import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout
    langKey="en"
    slug="/"
  >
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered is-size-8">
          <h1
            className="has-text-vivid-red has-text-weight-bold"
            style={{padding: '1rem'}}
          >
            This page doesn't seem to exist.
          </h1>
          <h2 className="has-text-weight-bold">
            It looks like the link pointing here was faulty.
          </h2>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
